@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@font-face {
  font-family: 'OpenSans-R';
  src: url('assets/fonts/OpenSans-Regular.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-SB';
  src: url('assets/fonts/OpenSans-SemiBold.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-B';
  src: url('assets/fonts/OpenSans-Bold.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-B';
  src: url('assets/fonts/Nunito-Bold.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Nunito-R';
  src: url('assets/fonts/Nunito-Regular.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'NunitoSans-R';
  src: url('assets/fonts/NunitoSans-Regular.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'NunitoSans-SB';
  src: url('assets/fonts/NunitoSans-SemiBold.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'NunitoSans-B';
  src: url('assets/fonts/NunitoSans-Bold.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'NunitoSans-EB';
  src: url('assets/fonts/NunitoSans-ExtraBold.ttf');
  font-style: normal;
}

body {
    background-color: #f3f5f6;
    font-family: 'NunitoSans-R';
}

.center {
  display: flex;
  justify-content: center;
}
.ag-theme-alpine .ag-header-cell{
    background-color: #fff;
}
.header-title {
    border-bottom: solid 1px #ddd;
    padding-bottom: 5px;
    font-size: 1.125rem;
    font-family: 'NunitoSans-SB' !important;
}
.ng-slider {
    margin-top: 10px;
}
.clearfix-20 {
    padding: 20px;
    clear: both;
}
.bottom-text {
    color: #999595;
    font-size: 0.9rem;
    font-weight: normal;
}
.product-form label, .admin-bookings label {
    font-family: 'Nunito-B';
    /*font: normal normal bold 11px/15px;*/
    letter-spacing: 0px;
    color: #7C7575;
    opacity: 1;
    font-size: 0.6rem;
    line-height: 12px;
}
.product-form bigStrings {
/*    font-family: 'Nunito-B';
    //font: normal normal bold 11px/15px;
    letter-spacing: 0px;
    color: #7C7575;
    opacity: 1;
    font-size: 0.6rem;
    line-height: 12px;*/
    font-family: 'Nunito-B';
    top: 200px;
    left: 110px;
    width: 398px;
    height: 50px;
    /* UI Properties */
    text-align: left;
    /*font: 46px/63px;*/
    letter-spacing: 0px;
    color: #14274E;
    opacity: 1;
}
.product-form .form-group {
    padding: 0 20px;
    margin-top: 15px;
}

select.form-control {
  appearance: auto;
}

.testCheckboxes .gridCheckbox {
  display: inline-block;
  margin: 5px 20px 10px 0;
}
.gridCheckbox .form-check {
  padding-left: 0;
}
.gridCheckbox input.form-check-input {
  margin-top: 0;
}

.gridCheckbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.gridCheckbox label {
  position: relative;
  cursor: pointer;
  background: #fafafa;
  border: solid 1px #ddd;
  border-radius: 3px;
  color: #000;
  padding: 5px 22px 5px 5px;
  font-size: 0.9rem;
}

.gridCheckbox label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
}

.gridCheckbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.gridCheckbox input:checked + label {
  background-color: #465765 !important;
  color: #ffffff;
}

.product-form input[type=radio]{
  margin-top: 3px;
}

.product-form .datepicker {
    padding: 0 25px;
}
.book-now-btn-disabled{
    /*font-family: 'NunitoSans-SB' !important;*/
    font-family: 'Nunito-R' !important;
    font-size: 0.8rem !important;
    padding: 5px 20px 5px 20px;
    background: #B8B0B0 0% 0% no-repeat padding-box  !important;
    /*background-color: #14274E !important;*/

}
.book-now-btn{
    /*font-family: 'NunitoSans-SB' !important;*/
    font-family: 'Nunito-R' !important;
    font-size: 0.8rem !important;
    padding: 5px 20px 5px 20px;
    color:#FFF;
    background-color: #14274E !important;
    border-color: #14274E !important;
}
.book-now-btn:hover{
    background-color: #14274E !important;
}

.pop-up-btn-white{
    /*font-family: 'NunitoSans-SB' !important;*/
    font-family: 'Nunito-B' !important;
    font-size: 0.8rem !important;
    padding: 5px 20px 5px 20px;
    color:#14274E;
    background-color: #FFF !important;
    border: 1px solid #14274E;
    border-radius: 4px;
}

.pop-up-btn-blue{
    /*font-family: 'NunitoSans-SB' !important;*/
    font-family: 'Nunito-R' !important;
    font-size: 0.8rem !important;
    padding: 5px 20px 5px 20px;
    color:#FFF;
    background-color: #14274E !important;
    border-color: #14274E !important;
    border-radius: 4px;
    opacity: 1;
}
.summary-panel .booking-details td, .summary-panel th {
    font-size: 0.9rem;
    font-family: 'NunitoSans-SB' !important;
    min-width: 600 px;
}
.nav-link a {
color: red !important;
}
.admin-button
{
  /*background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #14274E;
  border-radius: 4px;
  opacity: 1;*/
  width: 97px;
  height: 35px;
  font-size: 14px;
  color: #14274E;
  background-color: #FFFFFF;
  /*border-radius: 4px;*/
  border: 1px solid #14274E;
  /*font: normal normal 600 13px/8px Nunito-R;*/
}
.admin-button-text
{
  font: normal normal 600 14px/8px Nunito-R;
  letter-spacing: 0px;
  color: #14274E;
  opacity: 1;

}
.admin-string-secondary
{
  font-size: 11px;
  color: #7c7575;
  font-weight: 700;
}
.admin-string-primary
{
  font-size: 14px;
  color: #7c7575;
  font-weight: 700;
}
.admin-mat-chip-list
{
  margin-left: 10px; cursor: pointer; padding: 5px; background-color: #DDE4E9; border: 1px solid #DAE2E8; border-radius: 4px;
}

.time-slot .total-sum {
    border-top: solid 1px #ddd;
}
.mbl-btn {
    margin: 15px 0;
}
.summary-panel .card-title {
    padding: 5px 15px 0 15px;
}
.summary-panel .booking-details td {
    padding: 0 15px;
    line-height: 16px;
}
.summary-panel .card-body {
    padding: 0;
}
.summary-slots-details h6 {
    font-size: 0.9rem;
}
.summary-slots h6, .sumarry-total-sum h6, .summary-panel h5, .summary-panel .court-title {
    font-family: 'NunitoSans-SB' !important;
    font-size: 1rem !important;
}
.summary-title {
    //margin: 15px;
    padding-top:20px;
    font-family: 'Nunito-B' !important;
    font-size: 0.85rem !important;
    color: #333333;
    text-transform: uppercase;
    //align: center;
}
.summary-slots, .sumarry-total-sum {
    border-top: solid 1px #ddd;
    clear: both;
    padding: 10px 15px;
    float: left;
    width: 100%;
    font-size: 0.9rem;
}
.blueText {
    //margin: 15px;
    font-family: 'Nunito-B' !important;
    /*font-size: 0.85rem !important;*/
    color: #333333;

    //align: center;
}
.orangeText{
  color:#EC8B5D;
  font-family: 'NunitoSans-B' !important;
 }


.no-data-slots {
    padding: 0px 15px;
    line-height: 25px;
}
/* New changes - Saudagar */
.sum-data:first-child {
    width: 30%;
    /* width: 50%; */
}
/* New changes - Saudagar */
.sum-data {
    display: inline-block;
    float: left;
    width: 22%;
    /* width: 25%; */
}
.sum-data:last-child {
    text-align: center;
}
.margin-0 {
    margin: 0;
}
.padding-20 {
    padding: 0 20px;
}
.summary-slots-row {
    border-bottom: solid 1px #ddd;
    padding: 10px 0;
    margin: 0;
}
.summary-slots-row:last-child {
    border-bottom: none;
}
.summary-slots-details .slot-data td {
    padding: 5px 0;
    font-weight: 600
}
.summary-slots-details table.slot-data {
    border-spacing: 0;
    margin-bottom: 10px;
}
h6.slot-title {
    margin-bottom: 0;
}
h6.session-title {
    font-size: 0.9rem;
    font-family: 'NunitoSans-SB';
}
.summary-sessions span {
    float: left;
    font-weight: 600;
    font-size: 0.9rem;
}
.session-title {
    color: #656161 !important;
    margin-bottom: 20px;
}
.slots-session:last-child {
    border-right: none;
}
.box-div {
    width: 35%;
    max-height: 42px;
    display: inline-block;
    margin: 0 5px;
    border: 2px solid #14274E;
    text-align: center;
    border-radius: 4px;
    padding: 2px;
    margin-bottom: 10px;
    color: #14274E;
}

.createNewButtonStyle
{
  background-color: #EC8B5D;
  color: #FFFFFF;
  width: 280px;
  height: 50px;
  margin-top: -30px;
  border-radius: 5px;
  font-size: 19px;
  font-family: 'Nunito-B'
}
.createNewButtonStyle:hover
{
  background-color: #EC8B5D;
  color: #FFF;
  transform: scale(1.05);
}
.close-button {
    background-color: transparent;
    border: #FFF;
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    color: #14274E; /* Adjust color as needed */
}

.aboutUsParagraph
{
  margin-left: 10px;
  font-size: 16px;
  text-align: justify;

}
.descriptionHeader
{
   font-family: 'Nunito-B';
   letter-spacing: 0px;
   color: #14274E;
}

.fieldName{
  font-size: 11px;
  height: 15px;
  color: #7c7575;
  font-weight: bold;
}
.blueFieldName{

  font-size: 13px;
  height: 15px;
  color: #14274E;
  font-weight: bold;

}

@media (max-width: 576px) {
     .display-adminMenu {
         display: list-item;
     }
     .hidden-mobile {
        display: none;
     }
/*     .navbarPosition {
       position: fixed;
       top:10 px;
       width: 100%;
     }*/
    .main-container {
        width:100%;
        position: relative;
        position: relative;
        top: 5px;
    }
    .content-wrapper {
            margin: 2px 10px 50px 10px;
            border-radius: 5px;
            background-color: #F3F4F6;
            background-color: #F3F4F6;
            font-size: 0.8125rem !important;

    }
    .adminContainer
    {

      justify-content: center;
      align-items: center;
      margin-top: 10px;
      background-color: #FFFFFF;
      font-size: 0.8125rem !important;
    }

    .dexterGrid
    {
      margin: 30px -20px 0px -25px;
    }



    body {
        background-color: #fff;

    }
    .footer-basic {

        padding-top: 5px;
        padding-bottom: 21px;
    }

    .boldStatements
    {
        width: 370px;
        height: 150px;
        color: #14274E;
        font-size: 35px;
        font-family: 'Nunito-B'

    }

    .description
    {
      width: 370px;
      height: 60px;
      margin-top: 8px;
      font-size: 16px;
      color: #14274e;
    }
    .createNewButtonStyle
    {
      background-color: #EC8B5D;
      color: #FFFFFF;
      width: 280px;
      height: 50px;
      margin-top: 20px;
      border-radius: 5px;
      font-size: 20px;
      font-family: 'Nunito-B'
    }
    .createNewButtonStyle:hover
    {
      background-color: #EC8B5D;
      color: #FFF;
      transform: scale(1.05);
    }

    .businessFeatures
    {
        margin-left: 20px;
        margin-right: 50px;
        min-width: 300px;

    }
}
@media (max-width:768px) {
    .hidden-mobile {
        display: none;
    }
    .small-image{
      height: 250px;
      width: 303px;
    }

    .box-div {
        max-width: 75px;
    }
    .slots-session {
        border-bottom: solid 1px #ddd;
        margin-bottom: 10px;
    }
    .slots-session:last-child {
        border-bottom: none;
    }
    p.paragraph, .product-form label, .form-control {
        font-size: 0.8125rem;
    }
    h6.session-title {
        font-size: 0.8rem;
    }
    .slots-session {
        width: 90%;
        float: left;
        padding: 0 7px;
    }
    .slots-session .row {
       margin: 0 -15px !important;
    }
    .box-div .time-h6 {
        font-family: 'OpenSans-SB';
        font-size: 0.75rem;
        font: normal normal normal 15px/18px;
        margin-bottom: 0.1rem !important;
    }
    .box-div {
        padding: 2px;
    }
    .box-div .price-sp {
        font-size: 0.7rem;
    }
    .content-wrapper {
        margin: 2px 10px 100px 10px;
        border-radius: 5px;
        background-color: #F3F4F6;
        font-size: 0.8125rem !important;

    }
    .search-form {
        margin: 5px -5px !important;
        font: normal 12px sans-serif;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 5px;
    }
    .navbar-expand-md>.container {
        background-color: #fff;
        z-index: 999;

    }
    .marg-right-mobile {
        margin-right: 18px;
    }
    .footer-basic {

        padding-top: 5px !important;
        padding-bottom: 20px;
    }

    .gridStyle{
            margin-top: 25px;
            height: 100;
            margin-left: 5px;
            margin-right: 5px;

    }
    .adminContainer
    {

         justify-content: center;
         align-items: center;
          width:100%;

          background-color: #FFFFFF;
    }

}
@media (min-width:768px) {

    .adminContainer
    {
         justify-content: center;
         align-items: center;
         padding:0;
         margin:0;
         margin:auto;
         background-color: #FFFFFF;
    }
    .container{
      width: 1030px;
    }

    .hidden-DesktopOrTablet {
      display: none;
    }
   .businessFeatures
   {
          margin-left: 50px;
          margin-right: 50px;
          min-width: 300px;

   }
    .gridStyle{
                margin-top: 25px;
                height: 100;
                margin-left: 5px;
                margin-right: 5px;

    }
    p.paragraph, .product-form label, .form-control {
        font-size: 0.8rem;
        font-family: 'Nunito-B';
        font: normal normal normal 13px/18px;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }
    .content-wrapper {
        /*margin: 20px 40px;*/
        margin: 2px 10px 50px 10px;
        margin: 17px;
        border-radius: 5px;
        background-color: #F3F4F6;
        font-size: 14px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px #00000008;
    }
    .search-form {
        margin: 20px -15px !important;
        font: normal 12px sans-serif;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 5px;
    }
    .box-div {
            min-width: 65px;
    }

    .boldStatements
    {
        width: 370px;
        height: 150px;
        color: #14274E;
        font-size: 40px;
        font-family: 'Nunito-B'

    }
    .description
    {
      width: 370px;
      height: 60px;
      margin-left: 20px;
      margin-top: 8px;
      font-size: 18px;
      color: #14274e;
    }
    .box-div h6.time-h6 {
        font-family: 'OpenSans-SB';
        font: normal normal normal 12px/16px;
        /*line-spacing: 25px;
        letter-spacing: 0px;*/
        color: #14274E;
        opacity: 1;
        font-size: 0.8rem;
        margin-bottom: 0.05px !important;
    }
    .box-div .price-sp {
        font-size: 0.25rem;
        font-family: 'OpenSans-SB';
        font: normal normal normal 8px/11px;
        letter-spacing: 0px;
        color: #7C7575;
        opacity: 1;
    }
    .slots-session {
        border-right: solid 1px #ddd;
    }
    .footer-basic {
        height: 60px;
        padding-top: 5px;
        padding-bottom: 21px;
    }
}
.card {
    background-color: #ffffff;
    border: 1px solid rgba(0, 34, 51, 0.1);
    box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
    border-radius: 0.15rem;
}

/* Tabs Card */

.tab-card {
  border:1px solid #eee;
}

.tab-card-header {
  background:none;
  border-bottom: solid 1px #ddd;
}
/* Default mode */
.tab-card-header > .nav-tabs {
  border: none;
  margin: 0px;
}
.tab-card-header > .nav-tabs > li {
    width: 50%;
    box-sizing: border-box;
    text-align: center;
}
.tab-card-header > .nav-tabs > li > a {
  border: 0;
  border-bottom:2px solid transparent;
  margin-right: 0;
  color: #000;
  padding: 2px 0;
  font-size: 14px;
  font-family: 'NunitoSans-SB';
}

.tab-card-header > .nav-tabs .nav-link.active {
    border-bottom:2px solid #000;
    color: #000;
}
.tab-card-header > .nav-tabs > li > a:hover {
    color: #000;
    border-bottom:2px solid #000;
}

.tab-card-header > .tab-content {
  padding-bottom: 0;
}
.tab-content{
    padding-top: 10px;
}
.mobile-bottom-btns .mobile-btn {
   width: 50%;
   display: inline-block;
   background-color: #fff;
   height: 50px;
}
.mobile-bottom-btns .btn {
    padding: 10px;
    text-align: center;
    display: block;
    margin: 0 auto;
}
.mobile-bottom-btns .book-now {
    background-color: #14274E;
}
.mobile-bottom-btns .book-now .btn {
    color: #fff;
}
.mobile-bottom-btns .btn {
    text-decoration: none;
    font-weight: 600;
}
.search-form input {
    padding-left: 0 !important;
    color: #14274E !important;
}
.content-wrapper .container-fluid, .content-wrapper .container {
    background-color: #fff;
    min-height: 400px;
}


.court-box:first-child {
    margin-top: 0;
}
.court-box {
    margin-top: 40px;
}
.court-box .text {
    font-size: 0.7rem;
    font-family: 'Nunito-B';
    font: bold 8px;
    line-height: 15px;
    text-transform: uppercase;
    opacity:1;
    color: #333333;
}
.amenity-info {
    margin-bottom: 15px;
}
.amenity-icon {
    border: solid 1px #ddd;
    vertical-align: middle;
    margin-right: 7px;
    padding: 4px;
   /* position: relative;
    top: -10px;*/
}
.amenity-info i, .amenity-info label {
    display: inline-block;
}
.amenity-info label {
    width: 70%;
    line-height: 16px;
}
 .ng-image-slider-container .img-div img {
    border-radius: 0 !important;
 }
 .breadcrumb {
    margin-bottom: 0 !important;
 }
 .mobile-navigator {
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
 }
 .mobile-navigator i{
    margin-top: 12px;
 }
 .mobile-navigator .mobile-title {
    font-family: 'NunitoSans-SB';
 }
 .add-slot-mobile {
    font-family: 'NunitoSans-SB';
    font-size: 12px;
    position: fixed;
    top: 18px;
    right: 20px;
    z-index: 1500;
 }
 .add-slot-mobile a {
    cursor: pointer;
 }
 .form-control {
    border-radius: 2px !important;
 }
.footer {
            /*position: fixed;*/
            padding: 10px 10px 0px 10px;
            bottom: 0;
            width: 100%;
            /* Height of the footer*/
            height: 50px;
            background: grey;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.logo-image-container {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.mat-tab-label .mat-tab-label-content {
    font-weight: bold;
    text-transform: uppercase;
}
